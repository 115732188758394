import React from 'react';
import ReactHelmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

const Helmet = ({ theme = {} }) => (
  <StaticQuery
    query={graphql`
			query HelmetQuery {
				contentfulHeadSeo {
					name
					description
          profile {
            favicon16: resize(width: 16) {
              src
            }
          }
				}
			}
		`}
    render={({contentfulHeadSeo}) => {
      const { name, description, profile: [profile] } = contentfulHeadSeo || {};
			return (
  <ReactHelmet htmlAttributes={{ lang: 'pl' }}>
    <meta charSet="utf-8" />
    <title>{name}</title>
    <meta name="description" content={description} />
    {/* <link rel="shortcut icon" href={`https:${profile.favicon32.src}`} /> */}
    <meta name="theme-color" content={theme.background} />
    {/* <meta name="image" content={`https:${profile.favicon32.src}`} /> */}
    <meta itemProp="name" content={name} />
    <meta itemProp="description" content={description} />
    {/* <meta itemProp="image" content={`https:${profile.favicon32.src}`} /> */}
    <meta name="og:title" content={name} />
    <meta name="og:description" content={description} />
    {/* <meta name="og:image" content={`https:${profile.bigIcon.src}`} /> */}
    <meta name="og:site_name" content={name} />
    <meta name="og:locale" content="en_US" />
    <meta name="og:type" content="website" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={name} />
    <meta name="twitter:description" content={description} />
    {/* <meta name="twitter:image" content={`https:${profile.bigIcon.src}`} /> */}
    {/* <meta
      name="twitter:image:src"
      content={`https:${profile.bigIcon.src}`}
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={`https:${profile.appleIcon.src}`}
    /> */}
    {/* <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={`https:${profile.favicon32.src}`}
    /> */}
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={`https:${profile.favicon16.src}`}
    />
  </ReactHelmet>
			);
		}}
  />
);

Helmet.propTypes = {
  // eslint-disable-next-line
  theme: PropTypes.object,
};

export default withTheme(Helmet);
