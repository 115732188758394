import React from 'react';
import styled from 'styled-components';
import { Section } from 'react-scroll-section';
import { Heading } from 'rebass';
import PropTypes from 'prop-types';
import Slide from 'react-reveal/Slide';
import LinkAnimated from './LinkAnimated';

const SectionContainer = styled.div`
  min-height: ${props => props.minHeight || '100vh'};
  position: ${props => props.position || 'static'};
  right: ${props => props.right || 'unset'};
  left: ${props => props.left || 'unset'};
  top: ${props => props.top || 'unset'};
  background:  ${props => props.background || 'unset'};
  min-width: 320px;
  max-width: ${props => props.maxWidth || '1366px'};
  display: flex;
  margin: auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.padding || '5em 1em'};
  scroll-behavior: smooth;
`;

const DefaultBackground = () => <div />;

const Container = ({ id, file, children, Background = DefaultBackground, sectionStyles = {}, containerStyles={} }) => (
  <Section id={id} style={{ position: 'relative', ...sectionStyles }}>
    <Background file={file} />
    <SectionContainer {...containerStyles}>{children}</SectionContainer>
  </Section>
);

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  Background: PropTypes.func,
};

const Header = ({ name, icon = '', label = '' }) => (
  <Slide left>
    <Heading color="secondaryDark" mb={4}>
      <LinkAnimated selected>
        {name}
        {icon && (
          <span role="img" aria-label={label} style={{ marginLeft: '10px' }}>
            {icon}
          </span>
        )}
      </LinkAnimated>
    </Heading>
  </Slide>
);

Header.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
};

export default {
  Container,
  Header,
};
