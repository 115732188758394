module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#000',

  primary: '#000',
  primaryLight: '#000',
  primaryDark: '#000',
  logoFont: '#FFF',

  primaryFont: '#FFF',
  
  secondary: '#FFC03A',
  secondaryLight: '#000',
  secondaryDark: '#000',
  buttons: {
    primary: {
      color: '#000',
      bg: '#FFC03A',
    }
  }
};
